import Link from "next/link";
import Image from "next/image";
import { featureToggles } from "../../helpers/feature-toogles";
import { useSession } from "next-auth/react";
import { useEffect } from "react";

export default function Widgets({ type, title, showPromo, data, category = "" }) {
  const { data: session } = useSession();

  useEffect(() => {
    const win = window;
    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }

    win.Featurebase("initialize_feedback_widget", {
      organization: "notionwidgets",
      theme: "light",
    });
  }, [session]);

  const handleGiveFeedback = () => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "openFeedbackWidget",
      },
    });
  };

  const getFilteredData = () => {
    if (category) {
      return data.filter((widget) => widget.category === category);
    }
    return data;
  };

  return (
    <section id="widgets-section" className="py-5">
      <h2 className="mb-4 h4">{title}</h2>
      <div className="row">
        {getFilteredData().map((widget) => {
          const link = widget.isComing ? "#/" : widget.isPartner ? `/partner/${widget.slug}` : widget.isNext ? `/next/${widget.slug}` : `/${type}/${widget.slug}`;
          return (
            <div className="px-4 my-4 col-12 col-sm-3 px-sm-3" key={widget._id}>
              <Link href={link} className="text-decoration-none text-reset c-p position-relative" key={widget._id}>
                <div
                  className={`rounded-3 ${
                    widget.isComing
                      ? "border tw-h-full tw-bg-[#f6f9fc] tw-cursor-auto"
                      : widget.isPartner
                      ? "tw-border-red-300 tw-h-full tw-border-solid tw-border-2 tw-bg-red-50 hover:tw-bg-red-50 hover:tw-shadow-xl"
                      : widget.isNext
                      ? "tw-border-red-300 tw-h-full tw-border-solid tw-border-2 tw-bg-red-100 hover:tw-bg-red-100 hover:tw-shadow-xl"
                      : "border hover-card"
                  }`}
                  key={widget._id}
                >
                  <div className="items-center d-flex justify-content-center border-bottom" style={{ height: "150px" }}>
                    <img src={widget.thumbnail} className="img img-fluid rounded-top" alt={widget.title} />
                  </div>
                  <div className={`p-3 card-desc ${widget.isPartner || widget.isNext ? "tw-bg-none hover:tw-bg-none" : "bg-grey"}`}>
                    <h5>
                      {widget.title}
                      {widget.isNew && (
                        <span className="mx-1 mt-1 text-white align-top badge bg-primary rounded-pill" style={{ fontSize: "10px" }} title={"New Widget"}>
                          New
                        </span>
                      )}
                      {widget.isNext && (
                        <span className="mx-1 mt-1 text-white align-top badge bg-primary rounded-pill" style={{ fontSize: "10px" }}>
                          Coming soon
                        </span>
                      )}
                      {widget.isPartner && (
                        <span className="mx-1 mt-1 text-white align-top badge bg-primary rounded-pill" style={{ fontSize: "10px" }} title={"Partner"}>
                          Partner Widget
                        </span>
                      )}
                      {widget.isComing && (
                        <span className="mx-1 mt-1 text-white align-top badge bg-primary rounded-pill" style={{ fontSize: "10px" }} title={"New Widget"}>
                          Release on Saturday
                        </span>
                      )}
                    </h5>
                    <p style={{ minHeight: "40px", marginBottom: "35px" }}>{widget.tagline}</p>
                    <div
                      className="position-absolute d-flex justify-content-between"
                      style={{
                        left: 15,
                        right: 10,
                        bottom: 15,
                        height: "30px",
                      }}
                    >
                      <span className="p-2 badge bg-success" title={"Widget Size"}>
                        {widget.size}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
        {showPromo && (
          <div className="px-4 my-4 col-12 col-sm-3 px-sm-3" key="charts">
            <Link
              // href={
              //   "https://tally.so#tally-open=wdE95o&tally-layout=modal&tally-width=600&tally-overlay=1&tally-emoji-text=👋&tally-emoji-animation=wave"
              // }
              href={`/widget/chart`}
              className="text-decoration-none text-reset c-p position-relative"
            >
              <div className="border rounded-3 hover-card">
                <div className="items-center d-flex justify-content-center border-bottom" style={{ height: "150px" }}>
                  <img src="/images/widgets/charts-for-notion.svg" className="img img-fluid rounded-top" alt="image" />
                </div>
                <div className="p-3 card-desc bg-grey">
                  <h5>
                    Charts for Notion
                    <span className="mx-1 mt-1 text-white align-top badge bg-primary rounded-pill" style={{ fontSize: "10px" }} title={"New Widget"}>
                      Join to Waitlist
                    </span>
                  </h5>
                  <p style={{ minHeight: "40px", marginBottom: "35px" }}>Turn Notion databases into insightful charts. Allows you to easily create shareable charts.</p>
                </div>
              </div>
            </Link>
          </div>
        )}
        <div className="px-4 my-4 col-12 col-sm-3 px-sm-3" key="call-to-action">
          {session ? (
            <div onClick={handleGiveFeedback} className="text-decoration-none text-reset c-p position-relative">
              <div className="border rounded-3 hover-card">
                <div className="items-center d-flex justify-content-center border-bottom" style={{ height: "150px" }}>
                  <img src="/images/widgets/request-widget.svg" className="img img-fluid rounded-top" alt="image" />
                </div>
                <div className="p-3 card-desc bg-grey">
                  <h5>{`Your ${title}`}</h5>
                  <p style={{ minHeight: "40px", marginBottom: "35px" }}>Tell us what you are looking for!</p>
                  <div className="btn btn-primary d-flex justify-content-center">Submit Now</div>
                </div>
              </div>
            </div>
          ) : (
            <Link
              href="https://tally.so#tally-open=mZ826V&tally-layout=modal&tally-width=600&tally-overlay=1&tally-emoji-text=👋&tally-emoji-animation=wave"
              target="_blank"
              className="text-decoration-none text-reset c-p position-relative"
            >
              <div className="border rounded-3 hover-card">
                <div className="items-center d-flex justify-content-center border-bottom" style={{ height: "150px" }}>
                  <img src="/images/widgets/request-widget.svg" className="img img-fluid rounded-top" alt="image" />
                </div>
                <div className="p-3 card-desc bg-grey">
                  <h5>{`Your ${title}`}</h5>
                  <p style={{ minHeight: "40px", marginBottom: "35px" }}>Tell us what you are looking for!</p>
                  <div className="btn btn-primary d-flex justify-content-center">Submit Now</div>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
}

import Head from "next/head";

import React from "react";
import PropTypes from "prop-types";

export const META_IMAGES = {
  DUPLICATE_IMAGE:
    "https://www.notion-widgets.com/images/apps/duplicate-page-meta.svg",
};

export default function SEO({
  title,
  description,
  canonical,
  image,
  keywords,
  twImage,
  path,
  icon,
}) {
  const siteURL = path;
  const SITE_NAME = "Notion Widgets";
  return (
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <link rel="icon" href={icon} />
      <meta name="description" content={description} />
      {keywords ? <meta name="keywords" content={keywords} /> : " "}
      {canonical ? <link rel="canonical" href={canonical} /> : " "}
      {canonical ? <meta name="robots" content="index,follow" /> : " "}
      <meta property="og:type" content={"website"} />
      <meta property="og:url" content={siteURL} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={SITE_NAME} />

      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={siteURL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={twImage || image} />
    </Head>
  );
}

SEO.defaultProps = {
  title: "Apps & Widgets Made for Notion | Notion Widgets",
  description:
    "Best Apps and Widgets made for notion - Pomodoro Timer, Countdown Timer, Recurring Tasks, Life Progress, World Clock, and more.",
  image: "https://www.notion-widgets.com/images/meta.svg",
  keywords:
    "Notion Widgets, Notion Tools, Recurring tasks, Pomodoro Timer, Countdown timer, life progress, year progress, month progress, world clock",
  twImage: "",
  path: "https://www.notion-widgets.com/",
  icon: "https://www.notion-widgets.com/favicon.ico",
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
  twImage: PropTypes.string,
  path: PropTypes.string,
  icon: PropTypes.string,
};

export const featureToggles = {
  betaTesters: [
    "ayeshazahid1295@gmail.com",
    "gbfconcepcion0322@gmail.com",
    "tya@ebsuniversal.com",
    "hashemghunaim18@gmail.com",
    "pauline.rly.hrn@gmail.com",
    "alejandradelcid1996@gmail.com",
    "mgags3@outlook.com",
    "npiperno10@gmail.com",
    "ariela.crestani@dasa.com.br",
    "drealuche@gmail.com",
    "brunnajonhsson.f@gmail.com",
    "0leticia0pereira0costa0@gmail.com",
    "kimmoonsunhee@gmail.com",
    "oliviarig@gmail.com",
    "llu349212@gmail.com",
    "rafaelsarodrigues@gmail.com",
    "juanjo.cardan05@gmail.com",
    "sriashrithaappalchity07@gmail.com",
    "tammiehello357@gmail.com",
    "elissa.saadouni@icloud.com",
    "eckochannel25@gmail.com",
    "cjone019@gmail.com",
    "m.batuhanavci@gmail.com",
    "thibaud.lucchese@gmail.com",
    "ksuha.hui.sosi.777@gmail.com",
    "iaskarawara@gmail.com",
    "merve033022@gmail.com",
    "eng.mukhtar2000@gmail.com",
    "bonnie.hunt@providencechurch.org.au",
    "krishbp9@gmail.com",
    "007.dsilva@gmail.com",
    "bharath.chandra2208@gmail.com",
    "anhthu30120901@gmail.com",
    "aricompton26@gmail.com",
    "dumbyalien0310@gmail.com",
    "shaelina@helloseven.co",
    "chirag96gupta@gmail.com",
    "saadelabd91@gmail.com",
    "iamsurajbro946@gmail.com",
    "bj22152@astra.xlri.ac.in",
    "hchoo@nyu.edu",
    "omar@lamanchagallery.net",
    "jlhurst04@outlook.com",
    "dan.boss.muster@gmail.com",
  ],
};
